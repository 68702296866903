import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import queryString from 'qs';
import { compare } from 'compare-versions';
import { getRequestHeaderExperiments } from '../pages/publicShop/Constants';
import {
	BASE_API_URL,
	IS_MOBILE_APP,
	IS_ANDROID_APP,
	IS_IOS_APP,
	TYPO_CHECKER_TOKEN,
	REACT_APP_BUILD_NUMBER,
} from './Constants';
import {
	getAccessToken,
	removeAccessToken,
	refreshAccessToken,
	isAuthenticated,
} from './AccessToken';
import { getClientVersion, getLocalStorageItem } from './Helper';
import { historyReplace } from './History';

export function processRequest(
	url = '',
	method = 'GET',
	data = {},
	file = false,
	typoChecker = false,
	additionalHeaders = {},
) {
	let headers = {
		'Content-Type': file ? data.type : 'application/json',
		...additionalHeaders,
	};

	const isLoggedIn = isAuthenticated();
	const token = getAccessToken();
	if (token && !typoChecker) headers = { ...headers, 'X-Auth-Token': token };
	if (typoChecker && TYPO_CHECKER_TOKEN)
		headers = { ...headers, Authorization: TYPO_CHECKER_TOKEN };

	let clientType = 'web';
	if (IS_ANDROID_APP) {
		clientType = 'android';
	} else if (IS_IOS_APP) {
		clientType = 'ios';
	}
	headers = {
		...headers,
		'X-Client-Type': clientType,
		'X-Current-Client-Version': REACT_APP_BUILD_NUMBER,
	};

	const clientVersion = getClientVersion();
	if (clientVersion) headers = { ...headers, 'X-Client-Version': clientVersion };

	const selectedLanguage = getLocalStorageItem('selectedLanguage');
	if (selectedLanguage) headers = { ...headers, 'X-Language-Code': selectedLanguage };

	const selectedUserLocale = getLocalStorageItem('selectedLocale');
	if (selectedUserLocale) headers = { ...headers, 'X-Locale-Code': selectedUserLocale };

	// Tracking of non-registered users for experiments
	const experiments = getRequestHeaderExperiments();
	if (!isLoggedIn && !_isEmpty(experiments)) headers = { ...headers, ...experiments };

	// const handleUpgradeStatus = (currentClientVersion) => {
	// 	if (compare(currentClientVersion, clientVersion, '>')) {
	// 		localStorage.userCanUpgradeApp = true;
	// 	} else {
	// 		localStorage.removeItem('userCanUpgradeApp');
	// 	}
	// };

	axios.interceptors.response.use(
		async (response) => {
			// if (
			// 	IS_MOBILE_APP &&
			// 	response.headers['x-min-client-version'] &&
			// 	compare(response.headers['x-min-client-version'], clientVersion, '>')
			// ) {
			// 	historyReplace('/force-update');
			// }
			//
			// if (IS_MOBILE_APP && response.headers['x-current-client-version']) {
			// 	handleUpgradeStatus(response.headers['x-current-client-version']);
			// }

			return response;
		},
		async (error) => {
			const { status, data: error_data } = error.response || {};
			const { error_type, error_code } = error_data || {};

			if (
				status === 401 &&
				((error_type === 'invalid_token' && error_code === null) ||
					error_code === 'invalid_refresh_token')
			) {
				removeAccessToken();
				historyReplace('/inloggen');
			}

			if (status === 401 && error_code === 'access_token_expired') {
				await refreshAccessToken();

				return axios.request({
					...error.config,
					headers: { ...error.config.headers, 'X-Auth-Token': getAccessToken() },
				});
			}

			return Promise.reject(error);
		},
	);

	return axios({
		headers,
		method,
		params: method === 'GET' ? data : {},
		data: method === 'GET' ? {} : data,
		crossDomain: true,
		mode: 'cors',
		url: file || typoChecker ? url : BASE_API_URL + encodeURI(url),
		paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'brackets' }),
	});
}
